<template>
  <b-card>
    <template #header>
      <div class="w-100">
        <b-button
          variant="primary"
          class="float-left mb-2"
          @click="filterShow = !filterShow"
        >
          <template v-if="filterShow">
            <feather-icon icon="XIcon" />
            {{ $t('Қидирувни ёпиш') }}
          </template>
          <template v-else>
            <feather-icon icon="SearchIcon" />
            {{ $t('Қидириш') }}
          </template>
        </b-button>
        <b-button
          v-if="$route.name === 'reserve-index'"
          variant="primary"
          class="float-right mb-2"
          @click="$router.push({ name: 'reserve-create' })"
        >
          <feather-icon icon="UserPlusIcon" />
          {{ $t('Захирага қўшиш') }}
        </b-button>
      </div>
    </template>
    <div
      v-if="filterShow"
      class="custom-search"
    >
      <!-- advance search input -->
      <b-row>
        <b-col md="4">
          <b-form-group>
            <label>СТИР:</label>
            <b-form-input
              v-model="filter.person_tin"
              v-mask="'#########'"
              placeholder="000000000"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Фамилия:</label>
            <b-form-input
              v-model="filter.person_surname"
              placeholder="Фамилия"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Исм:</label>
            <b-form-input
              v-model="filter.person_name"
              placeholder="Исм"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Отасининг исми:</label>
            <b-form-input
              v-model="filter.person_patronymic"
              placeholder="Отасининг исми"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>ЖШШИР:</label>
            <b-form-input
              v-model="filter.person_pin"
              v-mask="'##############'"
              placeholder="00000000000000"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </b-col>
        <template v-if="$route.name !== 'organization-show'">
          <b-col
              md="2"
              sm="6"
              xs="6"
          >
            <b-form-group>
              <label>Ёши (дан):</label>
              <b-form-input
                  v-model="filter.age_from"
                  placeholder="Search"
                  type="number"
                  class="d-inline-block"
              />
            </b-form-group>
          </b-col>
          <b-col
              md="2"
              sm="6"
              xs="6"
          >
            <b-form-group>
              <label>Ёши (гача):</label>
              <b-form-input
                  v-model="filter.age_to"
                  placeholder="Search"
                  type="number"
                  class="d-inline-block"
              />
            </b-form-group>
          </b-col>
        </template>
        <b-col md="4">
          <b-form-group>
            <label>Жинси:</label>
            <b-form-select
              v-model="filter.person_sex"
              :options="genders"
              placeholder="Search"
              type="number"
              class="d-inline-block"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="$route.name !== 'organization-show'"
          md="4"
        >
          <b-form-group>
            <label>Ташкилот СТИР:</label>
            <b-form-input
              v-model="filter.company_tin"
              v-mask="'#########'"
              placeholder="000000000"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="$route.name !== 'organization-show'"
          md="4"
        >
          <b-form-group>
            <label>Ташкилот номи:</label>
            <b-form-input
              v-model="filter.profile_name"
              type="text"
              class="d-inline-block"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            variant="primary"
            class="float-right mb-2"
            @click="$emit('getPersons')"
          >
            <feather-icon icon="SearchIcon" />
            {{ $t("Қидириш") }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      disabled-sort
      :rows="items"
      :rtl="direction"
      :sort-options="{ enabled: false }"
      theme="my-theme"
      class="reserve"
    >
      <div slot="emptystate">
        <div class="w-100 text-center">
          {{ $t('Маълумот йўқ') }}
        </div>
      </div>
    </vue-good-table>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-0 mt-1">
        <span class="text-nowrap">
          {{ $t('Кўрсатиляпти') }}
        </span>
        <b-form-select
          v-model="filter.per_page"
          :options="['5','10', '20']"
          class="mx-1"
        />
        <span class="text-nowrap"> {{ $t('та') }}  <b>{{ total }}</b> {{ $t('тадан') }}  </span>
      </div>
      <div>
        <b-pagination
          :value="3"
          :total-rows="total"
          :per-page="filter.per_page"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="onPageChange"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import store from '@/store/index'

export default {
  components: {
    BCard,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BButton,
    BCol,
  },
  props: {
    columns: {
      type: Array,
      default() {
        return []
      },
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    filter: {
      type: Object,
      default() {
        return {}
      },
    },
    total: {
      type: Number,
      default() {
        return 0
      },
    },
    page: {
      type: Number,
      default() {
        return 1
      },
    },
  },
  data() {
    return {
      genders: [
        { value: true, text: 'Эркак' },
        { value: false, text: 'Аёл' },
      ],
      filterShow: false,
    }
  },
  computed: {
    direction() {
      return store.state.appConfig.isRTL
    },
  },
  methods: {
    onPageChange(page) {
      this.$emit('onPageChange', page)
    },
  },
}
</script>
<style>
  .reserve.vgt-wrap  * {
    font-size: 12px;
  }
</style>
